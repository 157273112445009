// @import '_bootstrap.scss';
// @import url("micons/micons.css");
@import url("/css/fonts.css");

/** Variables. */
$background_color:#f5f5f5;
$header_active_bar_color: #151515;
$header_text_color: #898989;
$body_text_color: #4e4e4e;
$plack_bar_color: #b9b9b9;
$primary_text_color:#898989;

$featured_background_color:#023400;
$price_background:#960000;
$price_text_color:#960000;
$section_heading_background_color:#888888;
$section_heading_color:#f0f0f0;


$pagenation_disabled_color: #ccc9c9ff;
$pagenation_disabled_background_color: #ccc9c926;

$pagenation_color: #302f2f;
$pagenation_background_color: #dbdbdb;
$pagenation_hover_background_color: #afafaf;

$buzz_source_color: #838383;
$buzz_title_color: #302f2f;
$buzz_link_color: #575656;


$news_page_title_color: #9b9999;

$title_font: "montserrat-bold";

// -------------

$primary_color:#007430;
$secondary-color:#eee;
$hover-color:#ffff00;


$item_background:#eee;
$info_background:#fff;
$text_color:#363636;
$font: 16px;
$footer_height: 60px;

body {
    background: $background_color;
    font-family: "roboto-regular", sans-serif;
	color: #111111;
	-webkit-font-smoothing: antialiased;
}

@media (min-width: 320px) and (max-width: 480px) {
	.main-buy {
		margin: 0px;
		padding: 0px;
	}

	.col-adj {
		padding: 0px;

	}

	.row {
		margin-left: 0px;
		margin-right: 0px;
	}

}

.container-fluid {
	max-width: 1280px;

	@media (min-width: 320px) and (max-width: 480px) {
		margin: 0px;
		padding: 0px;
	}

}

.navbar {
	// background: #DBDBDB url(../images/escheresque_@2X.png) repeat;
	// background: #DBDBDB url(../images/spiration-light.png) repeat;
	// background: rgba(36, 34, 34, 0.747) url(../images/what-the-hex.png) repeat;
	background: rgba(36, 34, 34, 0.747) url(../images/sayagata-400px.png) repeat;
	// background-size: 36px 19px;
}


.main-nav {
	padding-right: 85px;
	font-family: "montserrat-regular", sans-serif;
	font-size: 15px;

	@media (min-width: 320px) and (max-width: 480px) {
		margin: 0px;
		padding: 10px;
	}
	

	li {
		a {
			color: #898989;
		}
	}
	
	a.active {
		border-bottom: 2px solid $header_active_bar_color;
	}
}

.card {
	border-radius: 0;
	margin: 20px;
	border: 0px;
	
	@media (min-width: 320px) and (max-width: 480px) {
		margin: 5px;
	}

}


a {
	text-decoration: none;
	color: $buzz_link_color;
	line-height: inherit;
	font-family: $title_font, sans-serif;
}

a:hover {
	color:#000000 !important;
	border-bottom: 1px solid $header_active_bar_color;
	text-decoration: none;	
}

// -----------------------
// heading
// -----------------------

.page-heading-title {
	font-family: $title_font, sans-serif;

	text-transform: uppercase;
	font-size: 1.2rem;
	margin-top: 1rem;
	color: $news_page_title_color;
}

.page-heading-subtitle {
	font-family: $title_font, sans-serif;

	text-transform: uppercase;
	font-size: 0.8rem;
	color: $news_page_title_color;
}

// -----------------------
// share
// -----------------------
// ul.share-buttons{
// 	list-style: none;
// 	padding: 0;
//   }
  
//   ul.share-buttons li{
// 	display: inline;
//   }
  
//   ul.share-buttons .sr-only{
// 	position: absolute;
// 	clip: rect(1px 1px 1px 1px);
// 	clip: rect(1px, 1px, 1px, 1px);
// 	padding: 0;
// 	border: 0;
// 	height: 1px;
// 	width: 1px;
// 	overflow: hidden;
//   }



ul.share-buttons {
	list-style: none;
	padding: 0;

	li {
		display: inline;
		padding: 5px;

		a:hover {
			border: none;
		}
	  }
	
	  .sr-only{
		position: absolute;
		clip: rect(1px 1px 1px 1px);
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0;
		border: 0;
		height: 1px;
		width: 1px;
		overflow: hidden;
	  }

		
}
  
  


// -----------------------
// plank
// -----------------------

.brands {
	width:100%;
}

a.plack-link {
	color:#000000 !important;
	text-decoration:none;
}


.plack {
	background: transparent;
	// width: 130px;
	// height: 130px;
	// border: 1px solid $plack_bar_color;
	display: flex;
	flex-direction: column;
	align-content: center;
	// margin-top: 15px;
	padding: 5px;


	.plack-top {
		background-color: transparent;
		// height: 120px;

		margin: auto;
	}

	.plack-bottom {
		color: $primary_text_color;
		margin: auto;
	}

}

.plack:hover {
	border: 2px solid $header_active_bar_color;
	margin: -2px;

	.plack-bottom {
		color: $header_active_bar_color;

	}
}

// -----------------------
// listing
// -----------------------

.sort-heading {
	margin: 20px 20px 0px 20px;
	width:100%;

	h2 {
		font-weight: 800;
		font-size: 1.3rem;
		color: $section_heading_color;
		background-color: $section_heading_background_color;
		border: 1px solid lighten($section_heading_color, 5%);
		padding: 8px;

		@media (min-width: 320px) and (max-width: 480px) {
			font-size: 1rem;
		}

	}
}

// -----------------------
// articles
// -----------------------
.article-heading-title {
	font-size: 2rem;	
	margin-top: 0.9rem;
	margin-bottom: 0.9rem;
	font-family: $title_font, sans-serif;
}

.article {
	h2 {
		font-family: $title_font, sans-serif;
		font-size: 1.5rem;	
	}

	p {
		color: #111111;
	}

	// img {
	// }

	img.artpic {
		margin: 1rem;

		@media (min-width: 320px) and (max-width: 480px) {
			// float: none;
			display: flex!important;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;
			margin-left: auto!important;
			margin-right: auto!important;
		}

	}

}



.fp-list-group {
	.list-group-item {
		border: 0;
	}
}

// -----------------------
// buy list
// -----------------------
.index_lists {
	margin-top: 0.7rem;
}

.index-list-item {
	margin: 0px;

	.card-title {
		font-family: $title_font, sans-serif;
		color: $buzz_title_color;
		font-style: normal;
		text-rendering: optimizeLegibility;	
	}

}

.badge-link {
	color: white;
}

.badge-pill {
    padding-right: .6em;
    padding-left: .8em;
    padding-top: 0.5em;
    border-radius: 10rem;
	padding-bottom: 0.5em;
	min-width: 3rem;	
}

// -----------------------
// item - selling pages
// -----------------------

.item{
	background: lighten($primary_color, 85%);

	.card-title {
		font-family: $title_font, sans-serif;
		color: $buzz_title_color;
		font-style: normal;
		text-rendering: optimizeLegibility;
		text-transform: uppercase;

	}


	.row {
		margin-bottom: 0;
	}

	.card-body {
		padding: 0.5rem;
	}

	.col-md-4 {
		padding-right: 5px;
		padding-left: 5px;
	}

	.location {
		padding-bottom: 5px;
		font-size: 1.1rem;

		a {
			color: darken($price_background, 30%);
		}
	}

	.price{
		color: $price_text_color;
		border: 2px solid lighten($price_background, 0%);
		margin-top: 15px;
		font-weight: 800;
		padding: 5px 8px 5px 8px;
		float: none;
		@media (min-width: 320px) and (max-width: 480px) {
			background-color: $price_background;
			color: lighten($price_background, 95%);
			padding: 10px;
        }

		a {
			color: $price_text_color;
		}

		.cost{
			font-size:1.1rem;	
			@media only screen and (min-width: 640px) {
				font-size:2.0rem;
			}			
		}

		.price-check {
			font-size:0.7rem;	
			@media only screen and (min-width: 640px) {
				font-size:1rem;
			}
		}

	}

	.price:hover{
		background-color: $price_background;
		color: lighten($price_background, 95%);

		// border: 1px solid lighten($price_background, 0%);
		font-weight: 1200;
					
		.cost{
			font-size:2.0em;				
		}

		a {
			color: lighten($price_background, 95%);
		}
	}

	.stats {
		padding-top: 5px;

		h3{
			color: lighten($text-color, 20%);
			font-size:1.1rem;
			text-transform: uppercase;
			padding-bottom: 0px;
		}
	}

	p.snippet {
		color: $body_text_color;
		font-size: 0.9rem;
		line-height: 1.2rem;
		margin-bottom: 0em;

		a.read-more {
			padding-top: 0.5rem;
			color: darken($text-color, 20%);
		}
	}


	h2{
		color:$text-color;
		font-weight:normal;
		text-transform:none;
		line-height:0.9em;
		font-size:1em;
		text-align: left;
		padding-bottom: 10px;
		
		a{
			color: $text-color;
		}

		a:hover {
			text-decoration: none;
			font-weight:500;
			color: $text-color;
		}
		
		a:visited {
			color: $text-color;
		}

		a:active {
			color: $text-color;
		}

	}
}

.buy-fp {

	h5 {
		font-size: 1.1rem;
	}

	h6 {
		font-size: 0.9rem;
	}

	.list-group-item {
		padding: 0.25rem 0.75rem; 
	}

	.see-more {
		color: #000000;
		padding: 0.25rem 0.75rem; 
	}
}


// -----------------------
// buzz - news pages
// -----------------------
.buzz-row {
	display: flex;
	flex-wrap: wrap;
	// margin:5px;
	height: 100%;
	align-items:center;
	flex-direction: row;
	justify-content: space-around;
	
}

.fp-buzz{
	@media (min-width: 320px) and (max-width: 480px) {
		margin: 5px;
		min-width: initial; 
		max-width: 95%;	
	}

	background: lighten($primary_color, 85%);
	margin: 10px;
	text-align: center;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
	width: 100%;

	img {
		margin-top: auto;
		margin-bottom: auto;
	}


	.source {
		text-transform: uppercase;
		margin-bottom: 1rem;

		
		a {
			color: $buzz_source_color;	
		}
	}


	.card-title {
		font-family: $title_font, sans-serif;
		color: $buzz_title_color;
		font-style: normal;
		text-rendering: optimizeLegibility;

		a {
			text-decoration: none;
			color: $buzz_link_color;
			line-height: inherit;
		}
	
		a:hover, a:focus {
			color: $buzz_link_color;
			border-color: rgba(31, 31, 31, 0.05);
		}
	}

	.card-body {
		padding: 10px;
	}

	.summary {
	    font-size: 1rem;
		line-height: 1.7rem;
		color: #838383;	

	}
}

.buzz{
	@media (min-width: 320px) and (max-width: 480px) {
		margin: 5px;
		min-width: initial; 
		max-width: 95%;	
	}

	min-width: 200px; 
	max-width: 300px;
	background: lighten($primary_color, 85%);
	margin: 10px;
	text-align: center;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);


	.date {
		margin-bottom: 0.75rem;
	}

	.source {
		text-transform: uppercase;
		margin-bottom: 1rem;

		
		a {
			color: $buzz_source_color;	
		}
	}

	.card-subtitle {
		font-family: "muli-regular", sans-serif;
		font-size: 0.9rem;
		margin-top: 0;
	}

	.card-title {
		font-family: $title_font, sans-serif;
		color: $buzz_title_color;
		font-style: normal;
		text-rendering: optimizeLegibility;

		a {
			text-decoration: none;
			color: $buzz_link_color;
			line-height: inherit;
		}
	
		a:hover, a:focus {
			color: $buzz_link_color;
			// color: blueviolet;
			border-color: rgba(31, 31, 31, 0.05);
		}
	}

	.card-body {
		padding: 10px;
	}

	.summary {
	    font-size: 1rem;
		line-height: 1.7rem;
		color: #838383;	

	}
}

.buzz-list {
	.list-group-item {
		background-color: transparent;
		border: 0;
		padding: 0.25rem;
	}

	a {
		text-decoration: none;
		color: $buzz_link_color;
		line-height: inherit;
		text-transform: uppercase;
		font-family: $title_font, sans-serif;
	}

	a.active {
		color: #000000;
		border-bottom: 2px solid #151515;
	}	
}


// -----------------------
// Pagenation
// -----------------------


.page-item.disabled .page-link {
	color: $pagenation_disabled_color;
	pointer-events: none;
	cursor: auto;
	background-color: $pagenation_disabled_background_color;
	border-color: $pagenation_disabled_background_color;
}


.pagination {
    // margin: 3rem;
    text-align: center;


	.page-link {
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		font-family: $title_font, sans-serif;
		margin: 5px;
		color: $pagenation_color;
		background-color: $pagenation_background_color;
	}

	.active {
		background-color: $pagenation_color;
		color: $pagenation_background_color;
	}

	.page-link:hover {
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		background-color: $pagenation_hover_background_color;
	}	
}
